<template>
  <v-dialog
    value="true"
    :fullscreen="$vuetify.breakpoint.mdAndDown"
    :persistent="$vuetify.breakpoint.lgAndUp"
    transition="dialog-bottom-transition"
    :max-width="$vuetify.breakpoint.lgAndUp ? '600' : undefined"
    dark
    content-class="rounded-xl"
    style="padding-top: env(safe-area-inset-top);"
  >
    <v-card style="width: 100vw; overflow-x: hidden;">
      <v-toolbar
        fixed
        :color="template.colors.primary"
        :style="`color: ${template.colors.inline_primary_text};`"
        style="
          padding-top: env(safe-area-inset-top);
          height: 80px + env(safe-area-inset-top);
        "
      >
        <v-toolbar-title>Neue Sportstätte</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="closing()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-row justify="center" class="mt-4">
        <v-col cols="10" lg="8" class="px-2">
          <v-text-field
            v-model="sportstaette.name"
            filled
            rounded
            label="Name der Sportstätte"
          ></v-text-field>
          <v-text-field
            filled
            rounded
            label="Link"
            :prefix="`${template.var.app_url}/${user.data.alias}${
              sportstaette.main ? '' : '/'
            }`"
            :disabled="sportstaette.main"
            v-model="sportstaette.link"
            @input="check_link()"
            hint="Bitte trage hier z.B. den Namen deiner Sportstätte ein. Es dürfen keine Leerzeichen verwendet werden und keine anderen Sonderzeichen als ein Minus"
            persistent-hint
            :success-messages="linkok"
            :error-messages="linkexist"
          ></v-text-field>
          <v-text-field
            v-model="sportstaette.maxzuschauer"
            filled
            rounded
            type="number"
            label="Maximale Anzahl Personen"
          ></v-text-field>
          <v-btn
            class="my-4"
            block
            large
            rounded
            :loading="creating"
            :color="template.colors.primary"
            :style="`color: ${template.colors.inline_primary_text};`"
            :disabled="!linkok || !this.sportstaette.name"
            @click="create()"
          >
            <v-icon class="mr-2">mdi-plus</v-icon>
            Erstellen
          </v-btn>
          <v-btn
            class="my-4"
            block
            rounded
            large
            outlined
            :color="template.colors.primary"
            :style="`color: ${template.colors.inline_primary_text};`"
            @click="closing()"
          >
            Abbrechen
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
//import firebase from '../../../firebaseConfig'
import { mapGetters } from 'vuex'

export default {
  name: 'Sportstätte-erstellen',
  data() {
    return {
      sportstaette: {
        name: '',
        link: '',
        maxzuschauer: 300,
      },
      linkexist: '',
      linkok: '',
      creating: false,
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      template: 'template',
    }),
  },
  methods: {
    closing() {
      this.$emit('closing', true)
    },
    check_link() {
      this.linkexist = ''
      this.linkok = ''
      if (this.sportstaette.link.match(/(\\|\/){1,}/)) {
        this.linkexist = 'Der eingegebene Link enthält unzulässige Zeichen'
        return false
      }
      var i
      for (i = 0; i < this.user.data.sportstaetten.length; i++) {
        if (this.sportstaette.link == this.user.data.sportstaetten[i].link) {
          this.linkexist = 'Der eingegebene Link existiert bereits'
        }
      }
      if (this.linkexist) {
        return false
      } else {
        this.linkok = 'Dieser Link-Name ist verfügbar'
        return true
      }
    },
    create() {
      this.creating = true
      this.user.data.sportstaetten.push(this.sportstaette)
      firebase
        .firestore()
        .collection('User')
        .doc(this.user.data.uid)
        .update({
          sportstaetten: this.user.data.sportstaetten,
        })
        .then(() => {
          this.creating = false
          this.closing()
        })
    },
  },
}
</script>
