<template>
  <div>
    <v-row class="mt-3 pt-5">
      <v-col cols="auto" align-self="center">
        <v-btn icon to="/verein" exact>
          <v-icon large>mdi-chevron-left</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="auto" class="text-center" align-self="center">
        <v-icon x-large>mdi-stadium-variant</v-icon>
      </v-col>
      <v-col align-self="center">
        <h3 class="white--text font-weight-bold">
          SPORTSTÄTTEN VERWALTEN
        </h3>
      </v-col>
      <v-col cols="auto" v-if="user.data.sportstaetten">
        <v-btn
          :fab="$vuetify.breakpoint.smAndDown"
          :text="$vuetify.breakpoint.mdAndUp"
          @click="newplace = true"
        >
          <v-icon class="mr-md-2">mdi-plus</v-icon>
          <span v-if="$vuetify.breakpoint.mdAndUp">Neue Sportstätte</span>
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mt-4">
      <v-row justify="center" v-if="user.data.sportstaetten">
        <v-col
          cols="11"
          class="pa-0 ma-0"
          v-for="(places, index) in user.data.sportstaetten"
          :key="index"
        >
          <cardbutton
            :title="places.name"
            icon="mdi-stadium"
            :textcolor="template.colors.block_text"
            :bgcolor="template.colors.blocks"
            upper
            chev
            :to="`/verein/sportstaetten/${index}`"
          />
        </v-col>
      </v-row>
      <v-col
        cols="12"
        v-if="!user.data.sportstaetten || user.data.sportstaetten.length == 0"
      >
        <v-card dark>
          <v-row style="min-height: 30vh;" justify="center" v-if="wizard == 0">
            <v-col cols="11" align-self="center" class="text-center">
              <h3 class="font-weight-light">
                Es wurden derzeit noch keine weiteren Sportstätten für deinen
                Verein angelegt. Starte jetzt damit weitere Sportstätten für
                deinen Verein zu erstellen. Eine weitere Sportstätte sollte
                einer der folgenden Kriterien entsprechen: Geografisch getrennte
                Sportanlagen, unterschiedliche Räume in denen sich die Personen
                aufhalten (z.B. Sporthalle auf Sportgelände und Außenbereich auf
                Sportgelände).
              </h3>
            </v-col>
            <v-col cols="11" class="text-center">
              <v-btn color="primary" large @click="wizard = 1">
                Jetzt starten
              </v-btn>
            </v-col>
          </v-row>
          <v-row style="min-height: 30vh;" justify="center" v-if="wizard == 1">
            <v-col cols="11" align-self="center" class="text-center">
              <h2>Schritt 1: Hauptsportstätte</h2>
              <h3 class="font-weight-light">
                Gebe die Daten deiner Hauptsportstätte ein.
              </h3>
            </v-col>
            <v-col cols="11" class="text-center">
              <v-text-field
                label="Name der Hauptsportstätte"
                filled
                v-model="sportstaetten[0].name"
              ></v-text-field>
              <v-text-field
                label="Link"
                filled
                disabled
                :prefix="template.var.app_url + '/'"
                :value="user.data.alias"
                hint="Der Link für die Hauptsportstätte ist nicht veränderbar."
                persistent-hint
              ></v-text-field>
            </v-col>
            <v-col cols="11" class="text-center">
              <v-btn color="primary" large @click="wizard = 2">
                Weiter
              </v-btn>
            </v-col>
          </v-row>
          <v-row style="min-height: 30vh;" justify="center" v-if="wizard == 2">
            <v-col cols="11" align-self="center" class="text-center">
              <h2>Schritt 2: Weitere Sportstätte</h2>
              <h3 class="font-weight-light">
                Gebe die Daten deiner weiteren Sportstätte ein.
              </h3>
            </v-col>
            <v-col cols="11" class="text-center">
              <v-text-field
                label="Name der Sportstätte"
                filled
                v-model="sportstaetten[1].name"
              ></v-text-field>
              <v-text-field
                label="Link"
                filled
                :prefix="`${template.var.app_url}/${user.data.alias}/`"
                v-model="sportstaetten[1].link"
                persistent-hint
              ></v-text-field>
            </v-col>
            <v-col cols="11" class="text-center">
              <v-btn
                color="primary"
                :loading="loading"
                large
                @click="createSportstaetten()"
              >
                Weiter
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <CreateSportstaette v-if="newplace" v-on:closing="newplace = false" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
//import firebase from '../../../firebaseConfig'
import store from '../../../store'
import cardbutton from '../../../elements/cards/card-button'
import CreateSportstaette from './createSportstaette'

export default {
  name: 'Anwesenheitsliste',
  data() {
    return {
      benutzer: [],
      loading: false,
      datum: '',
      adatum: false,
      wizard: 0,
      newplace: false,
      sportstaetten: [
        {
          name: '',
          main: true,
        },
        {
          name: '',
          link: '',
        },
      ],
    }
  },
  components: {
    CreateSportstaette,
    cardbutton,
  },
  computed: {
    ...mapGetters({
      user: 'user',
      veranstaltung: 'veranstaltung',
      template: 'template',
    }),
  },
  methods: {
    addNull(val) {
      if (val > 10) {
        return val
      }
      return '0' + val
    },
    parseDate(date) {
      var datum = date.split('-')
      return datum[2] + '.' + datum[1] + '.' + datum[0]
    },
    createSportstaetten() {
      this.loading = true
      firebase.firestore().collection('User').doc(this.user.data.uid).update({
        sportstaetten: this.sportstaetten,
      })
    },
  },
}
</script>
